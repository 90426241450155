<template>
  <div>
      <nav-bar :wallet='false'></nav-bar>
      <div class="row justify-content-center p-0 m-0">
        <div class="col-12 col-lg-8 p-0 m-0">
          <div  class="px-3 py-5 my-5">
            <h1 class="txGold text-center mb-5">Thank You !</h1>
            <h5 class="text-center mb-3">FOR REGISTERING WITH <span class="txgold-right">fuse.gold</span>.</h5>
            <h5 class="text-center up">please check your emails to confirm your email address</h5>
          </div>
        </div>
      </div>
    <footer-section></footer-section>

  </div>
</template>

<script>


export default {
   components:{
    NavBar:() => import('@/components/NavBar.vue'),FooterSection:() => import('@/components/FooterSection'),

   },
    name: "ThankYou.vue",
    data(){
      return {
        
      }
    },
    // components:{},
    mounted(){
      this.start();
      this.love(window.screen.availWidth)
      // this.love()
    },
    beforeRouteLeave(to, from, next) {
    this.stop();
    next()

  },
    methods:{
      start() {
        this.$confetti.start(
          {}
        );
      },

      stop() {
        this.$confetti.stop();
      },

      love(width) {
        let w = (width > 666) ? 1 : .50

        this.$confetti.update({
          particlesPerFrame:w,
          particles: [
            {
              type: 'image',
              size: 15,
              dropRate:5,
              url:"https://fuseg-dev-app-u6wsh.ondigitalocean.app/fg.png"
              
            },
            {
              type: 'image',
              size: 15,
              dropRate:5,
              url:"https://fuseg-dev-app-u6wsh.ondigitalocean.app/goldx.png"
            },
            {
              type: 'image',
              size: 15,
              dropRate:5,
              url:"https://fuseg-dev-app-u6wsh.ondigitalocean.app/live.png"
            },
            
            
            // {
            //   type: 'circle',
            // },
          ],
          defaultColors: [
            'red',
            'pink',
            '#ba0000'
          ],
        });
      }
    }
}
</script>

<style>

</style>